import _ from "lodash"
import {DetailedHTMLProps, HTMLAttributes, forwardRef} from "react"
import {twMerge} from "tailwind-merge"

type OptionProps = DetailedHTMLProps<HTMLAttributes<HTMLUListElement>, HTMLUListElement>

const Options = forwardRef<HTMLUListElement, OptionProps>(({children, className, ...props}, ref) => {
  return (
    <ul
      ref={ref}
      {...props}
      className={twMerge(
        "flex max-h-80 min-h-0 flex-col gap-1 overflow-y-auto rounded-lg border border-solid border-gray-200 bg-white p-1.5 shadow-lg outline-none",
        className,
      )}
    >
      {children}
    </ul>
  )
})

Options.displayName = "Options"

export default Options
